import { convertMomentDate } from './date-format';

const dataFields: string[] = ['dateTo', 'dateFrom', 'dueDate', 'createdAtFrom', 'createdAtTo', 'endDate', 'startDate'];

export function replaceFields(obj: object, keyToDelete?: string[]): object {
  const copyObj = JSON.parse(JSON.stringify(obj));
  Object.keys(copyObj).forEach((k: string) => {
    if (keyToDelete?.length) {
      keyToDelete.forEach((key: string) => {
        delete copyObj[key];
      });
    }
    if (copyObj[k] === null || copyObj[k] === undefined || copyObj[k] === '' || (Array.isArray(copyObj[k]) && copyObj[k].length === 0)) {
      delete copyObj[k];
    } else if (Array.isArray(copyObj[k]) && copyObj[k].length) {
      const f: string = k + '[]';
      copyObj[f] = copyObj[k];
      delete copyObj[k];
    }
    if (copyObj[k] && dataFields.includes(k)) {
      copyObj[k] = convertMomentDate(copyObj[k]);
    }
  });

  return copyObj;
}
